import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src160420232/src/www.startresponse.com/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`Also from getting-started/index.mdx`}</p>
    </PageDescription>
    <h2>{`Heading 2 from getting-started/index.mdx`}</h2>
    <p>{`Hello again from getting-started/index.mdx`}</p>
    <h2>{`Yo from getting-started/index.mdx`}</h2>
    <p>{`Hello getting-started/index.mdx`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      